<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="136px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="引言" prop="proverb">
        <el-input type="textarea" :rows="2" v-model="form.proverb" placeholder="请输入引言"></el-input>
      </el-form-item>
      <el-form-item label="年级" prop="subject">
        <el-select v-model="form.subject" placeholder="请选择">
          <el-option
            v-for="item,index in options"
            :key="index"
            :label="item.label"
            :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述" prop="introduce">
        <el-input type="textarea" :rows="4" v-model="form.introduce" placeholder="请输入练习题描述"></el-input>
      </el-form-item>
      <el-form-item label="答案类型" prop="uploadFileType">
        <el-select v-model="form.uploadFileType" placeholder="请选择" @change="typeChange">
          <el-option
            v-for="item in answierType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.uploadFileType == 1">
        <el-form-item label="案例赏析图片" prop="casePicture">
          <el-upload
            :action="uploadURL"
            list-type="picture-card"
            :multiple="false"
            :headers="headers"
            :limit="1"
            v-model="form.casePicture"
            :fileList="caseFileList"
            :on-preview="handlePreview"
            :on-remove="handleCaseRemove"
            :on-success="handleCaseSuccess"
            :on-change="onChangae">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="跟踪练习图片" prop="practisePicture">
          <el-upload
            :action="uploadURL"
            list-type="picture-card"
            :multiple="false"
            v-model="form.practisePicture"
            :headers="headers"
            :limit="1"
            :fileList="practiseFileList"
            :on-preview="handlePreview"
            :on-remove="handlePractiseRemove"
            :on-success="handlePractiseSuccess"
            :on-change="onChangae">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="练习题答案" prop="answerPicture">
          <el-upload
            :action="uploadURL"
            list-type="picture-card"
            :multiple="false"
            :headers="headers"
            v-model="form.answerPicture"
            :fileList="answerFileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleAnswerRemove"
            :on-success="handleAvatarSuccess"
            :on-change="onChangae">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="练习题答案" prop="video">
          <el-upload
            :action="uploadVideoURL"
            list-type="text"
            :multiple="false"
            :headers="headers"
            v-model="form.video"
            :fileList="answerVideoFileList"
            :limit="1"
            :on-preview="handleVideoPreview"
            :on-remove="handleVideoRemove"
            :on-success="handleVideoSuccess"
            :on-change="onChangae">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </template>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true" :close-on-press-escape="false" :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog :visible.sync="videoVisible" :modal-append-to-body="false" :append-to-body="true" :close-on-press-escape="false" :close-on-click-modal="false">
      <video :src="dialogVideoUrl" controls autoplay class="video" width="100%" id="videoPlay"></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'studySignModal',
  props: ['oldForm'],
  data() {
    return {
      dialogImageUrl: '',
      dialogVideoUrl: '',
      videoVisible: false,
      dialogVisible: false,
      answierType: [
        { value: 1, label: '图片' },
        { value: 2, label: '视频' },
      ],
      options: [
        { label: '一年级' },
        { label: '二年级' },
        { label: '三年级' },
        { label: '四年级' },
        { label: '五年级' },
        { label: '六年级' },
        { label: '初一' },
        { label: '初二' },
        { label: '初三' },
        { label: '高一' },
        { label: '高二' },
        { label: '高三' },
      ],
     /*  options: [
        { value: 1, label: '一年级' },
        { value: 2, label: '二年级' },
        { value: 3, label: '三年级' },
        { value: 4, label: '四年级' },
        { value: 5, label: '五年级' },
        { value: 6, label: '六年级' },
        { value: 7, label: '初一' },
        { value: 8, label: '初二' },
        { value: 9, label: '初三' },
        { value: 10, label: '高一' },
        { value: 11, label: '高二' },
        { value: 12, label: '高三' },
      ], */
      uploadURL: baseURL + '/web/file/uploadImage',
      uploadVideoURL: baseURL + '/web/file/uploadVideo',
      headers: {
        'X-Access-Token': sessionStorage.getItem('token'),
      },
      fileList: [],
      answerVideoFileList: [],
      answerFileList: [],
      caseFileList: [],
      practiseFileList: [],
      files:[],
      dialogImageUrl:'',
      dialogVisible: false,
      rules: {
        title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
        proverb: [{required: true, message: '请输入引言', trigger: 'blur'}],
        subject: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        uploadFileType: [{ required: true, message: '请选择答案类型', trigger: 'blur' }],
        introduce: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        casePicture: [{ required: true, message: '请上传案例赏析图片', trigger: 'blur' }],
        practisePicture: [{ required: true, message: '请上传跟踪练习图片', trigger: 'blur' }],
        answerPicture: [{ required: true, message: '请上传练习题答案', trigger: 'blur' }],
        video: [{ required: true, message: '请上传练习题答案', trigger: 'blur' }],
      },
      form: {
        title: '',
        subject: '',
        uploadFileType: 1,
        introduce: '',
        casePicture: '',
        practisePicture: '',
        answerPicture: '',
        video: '',
      },
    }
  },
  mounted() {
    if(JSON.stringify(this.oldForm) == '{}') {
      
    }else {
      this.form = JSON.parse(JSON.stringify(this.oldForm));
    }
    if(this.form.casePicture) {
      this.caseFileList = [];
      this.files = [];
      let list = this.form.casePicture.split(',');
      for(let i = 0;i<list.length;i++) {
        let str = list[i];
        let name = str.substring(str.lastIndexOf('/')+1);
        name = name.substring(0,name.indexOf('_'));
        let obj = {name,url:baseURL+str,fileUrl:str}
        console.log(baseURL);
        this.caseFileList.push(obj);
      }
      // console.log(this.fileList);
    }
    if(this.form.practisePicture) {
      this.practiseFileList = [];
      this.files = [];
      let list = this.form.practisePicture.split(',');
      for(let i = 0;i<list.length;i++) {
        let str = list[i];
        let name = str.substring(str.lastIndexOf('/')+1);
        name = name.substring(0,name.indexOf('_'));
        let obj = {name,url:baseURL+str,fileUrl:str}
        this.practiseFileList.push(obj);
      }
      // console.log(this.fileList);
    }
    if(this.form.answerPicture) {
      this.answerFileList = [];
      this.files = [];
      let list = this.form.answerPicture.split(',');
      for(let i = 0;i<list.length;i++) {
        let str = list[i];
        let name = str.substring(str.lastIndexOf('/')+1);
        name = name.substring(0,name.indexOf('_'));
        let obj = {name,url:baseURL+str,fileUrl:str}
        this.answerFileList.push(obj);
      }
      // console.log(this.fileList);
    }
    if(this.form.video) {
      this.answerVideoFileList = [];
      this.files = [];
      let list = this.form.video.split(',');
      for(let i = 0;i<list.length;i++) {
        let str = list[i];
        let name = str.substring(str.lastIndexOf('/')+1);
        name = name.substring(0,name.indexOf('_'));
        let obj = {name,url:baseURL+str,fileUrl:str}
        this.answerVideoFileList.push(obj);
      }
      // console.log(this.fileList);
    }
  },
  methods: {
    onChangae(file, fileList) {
      // console.log(file, fileList);
    },
    typeChange(val) {
      console.log(val);
    },
    handlePreview(file) {
      // console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVideoPreview(file) {
      this.dialogVideoUrl = file.url;
      this.videoVisible = true;
    },
    handleCaseRemove() {
      this.caseFileList = [];
      this.form.casePicture = '';
    },
    handlePractiseRemove() {
      this.practiseFileList = [];
      this.form.practisePicture = '';
    },
    handleAnswerRemove() {
      this.answerFileList = [];
      this.form.answerPicture = '';
    },
    handleVideoRemove() {
      this.answerVideoFileList = [];
      this.form.video = '';
    },
    /* handleRemove(file, fileList) {
      console.log(file,fileList);
      if(file.fileUrl) {
        for(let i = 0;i<this.fileList.length;i++) {
          if(this.fileList[i].fileUrl === file.fileUrl) {
            this.fileList.splice(i,1);
            break;
          }
        }
      }else{
        if(file.status == 'success' && file.response && file.response.result) {
          for(let i = 0;i< this.files.length;i++) {
            if(file.response.result == this.files[i]) {
              this.files.splice(i,1);
            }
          }
        }
      }
      // debugger;
    }, */
    handleCaseSuccess(response, file) {
      if(response.success) {
        this.$message.success('上传成功! ');
        this.caseFileList.push({
          name:file.name,
          url: baseURL + response.result,
          fileUrl: response.result,
        });
        this.form = Object.assign(this.form, {casePicture: response.result})
        // this.form.casePicture = response.result;
        // this.form.casePicture =  'true';
      }else {
        this.$message.error(res.response.message);
      }
    },
    handlePractiseSuccess(response, file) {
      if(response.success) {
        this.$message.success('上传成功! ');
        this.practiseFileList.push({
          name:file.name,
          url: baseURL + response.result,
          fileUrl: response.result,
        });
        this.form.practisePicture = response.result;
      }else {
        this.$message.error(res.response.message);
      }
    },
    handleAvatarSuccess(response, file) {
      if(response.success) {
        this.$message.success('上传成功! ');
        this.answerFileList.push({
          name:file.name,
          url: baseURL + response.result,
          fileUrl: response.result,
        });
        this.form.answerPicture =  response.result;
      }else {
        this.$message.error(res.response.message);
      }
    },
    handleVideoSuccess(response, file) {
      if(response.success) {
        this.$message.success('上传成功! ');
        this.answerVideoFileList.push({
          name:file.name,
          url: baseURL + response.result,
          fileUrl: response.result,
        });
        this.form.video =  response.result;
      }else {
        this.$message.error(res.response.message);
      }
    },
  }
}
</script>

<style scoped lang="less">
  /deep/ .el-textarea__inner {
    max-height: 150px;
  }
</style>